<template>
  <!-- 媒体登録ダイアログ -->
  <v-dialog v-model="dialog" scrollable persistent width="1000px">
    <v-card :disabled="loginCheckLoading">
      <v-card-title>
        <span class="text-h5">媒体設定</span>
      </v-card-title>
      <v-card-text class="scrollable-content">
        <input type="hidden" v-model="item.id" />
        <ValidationObserver ref="observer">
          <h3>更新媒体名</h3>
          <div class="mt-2 mb-2"></div>
          <v-container>
            <v-row>
              <v-col cols="12" sm="8">
                <v-text-field
                  prepend-icon="mdi-application-outline"
                  v-model="showSite.name"
                  readonly
                  success
                  name="name"
                  label="媒体名"
                  outlined
                  hide-details="false"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <h3>ログイン設定</h3>
          <div class="mt-2 mb-2">
            <span class="text-red" v-if="item.id == 0"
              >新しく媒体を追加した後、ログインIDとパスワードは変更できなくなります。【変更が必要な際は運営までご連絡ください】<br
            /></span>
            <span class="text-blue" v-else
              >新規登録時以外は、ログインIDとパスワードは変更できません。【変更が必要な際は運営までご連絡ください】<br
            /></span>
            媒体（サイト）のログインに関する情報を設定してください。
            <span v-show="!hpLink"
              ><v-btn
                text
                color="primary"
                class="font-weight-bold pa-1 d-inline"
                :loading="loginCheckLoading"
                :disabled="loginCheckLoading"
                @click="checkLogin"
                ><span v-html="loginCheckButtonText"></span></v-btn
            ></span>
          </div>
          <v-container>
            <v-row>
              <v-col cols="12">
                <ValidationProvider
                  v-slot="{ errors, valid }"
                  name="ログイン画面のURL"
                  rules="required|url|max:300"
                >
                  <v-text-field
                    prepend-icon="mdi-cloud"
                    v-model="item.auth_url"
                    v-show="!urlHide"
                    :error-messages="errors"
                    :success="valid"
                    name="auth_url"
                    label="ログイン画面のURL"
                    outlined
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" sm="6">
                <ValidationProvider
                  v-slot="{ errors, valid }"
                  name="ログインID"
                  rules="required|max:100"
                >
                  <v-text-field
                    prepend-icon="mdi-account-outline"
                    v-model="item.loginid"
                    :error-messages="errors"
                    :success="valid"
                    name="auth_loginid"
                    label="ログインID"
                    outlined
                    :readonly="item.id != 0"
                    :background-color="item.id != 0 ? '#eee' : ''"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" sm="6">
                <ValidationProvider
                  v-slot="{ errors, valid }"
                  name="パスワード"
                  rules="required|max:100"
                >
                  <v-text-field
                    prepend-icon="mdi-lock"
                    v-model="item.password"
                    :error-messages="errors"
                    :success="valid"
                    name="auth_password"
                    label="パスワード"
                    outlined
                    :readonly="item.id != 0"
                    :background-color="item.id != 0 ? '#eee' : ''"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-container>
          <h3>対応コンテンツ一覧</h3>
          <div class="mt-2 mb-2">
            下記コンテンツの更新に対応しています。更新しないコンテンツはチェックを外してください。<br />
            <span v-if="item.site_id != setting.esutama_site_id">
              ※スケジュールにチェックを入れておくと毎日6時30分～7時30分の間に、登録されているスケジュールで自動更新します。
            </span>
            <span v-else class="red--text">
              ※スケジュールを自動更新する際は、スケジュールにチェックを入れて保存し、スケジュール管理画面で<span
                class="font-weight-bold"
                >「エステ魂更新設定」</span
              >ボタンをクリックして時刻を設定して下さい。
            </span>
          </div>
          <v-data-table
            :headers="headers"
            :items="siteContents"
            item-key="site_content_id"
            :items-per-page="-1"
            :mobile-breakpoint="0"
            hide-default-footer
            dense
            show-select
            :single-select="false"
            v-model="selected"
            no-data-text="対応コンテンツがありません"
            @click:row="rowClick"
          >
            <!-- eslint-disable-next-line -->
            <template v-slot:item.site_content_name="{ item }">
              <span class="text-align-middle"
                >&nbsp;{{ item.site_content_name }}</span
              >
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.content_name="{ item }">
              <v-icon> {{ item.content_icon }} </v-icon
              ><span class="text-align-middle"
                >&nbsp;{{ item.content_name }}</span
              >
            </template>
          </v-data-table>
          <h3 class="mt-12">個別設定</h3>
          <div class="mt-2 mb-2">
            媒体個別の情報があれば入力してください。例：エリア名、支店名など。カラーを選択すると、一覧画面で色分けされます。
          </div>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <ValidationProvider
                  v-slot="{ errors, valid }"
                  name="メモ"
                  rules="max:1000"
                >
                  <v-text-field
                    prepend-icon="mdi-text-box"
                    v-model="item.memo"
                    :error-messages="errors"
                    :success="valid"
                    label="メモ"
                    outlined
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" sm="6" id="color-selector">
                <ValidationProvider
                  v-slot="{ errors, valid }"
                  name="カラー"
                  rules="required"
                >
                  <v-text-field
                    prepend-icon="mdi-palette"
                    v-model="item.color"
                    hide-details
                    :error-messages="errors"
                    :success="valid"
                    label="カラー選択"
                    readonly
                    outlined
                  >
                    <template v-slot:append>
                      <v-menu
                        v-model="item.menu"
                        top
                        nudge-bottom="105"
                        nudge-left="16"
                        :close-on-content-click="false"
                      >
                        <template v-slot:activator="{ on }">
                          <div :style="swatchStyle(item)" v-on="on"></div>
                        </template>
                        <v-card>
                          <v-card-text class="pa-0">
                            <v-color-picker
                              v-model="item.color"
                              flat
                              show-swatches
                              mode="hexa"
                            />
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </template>
                  </v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-container>
        </ValidationObserver>
      </v-card-text>
      <v-card-actions>
        <v-container>
          <v-row justify="end">
            <v-col cols="6" sm="3">
              <general-button
                btn_color="gray"
                btn_block
                @click-event="cancelDialog"
                ><template v-slot:icon
                  ><v-icon left>mdi-close-circle</v-icon></template
                >キャンセル</general-button
              >
            </v-col>
            <v-col cols="6" sm="3">
              <general-button btn_type="info" btn_block @click-event="onSubmit"
                ><template v-slot:icon
                  ><v-icon left>mdi-content-save</v-icon></template
                >保存</general-button
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { reactive, toRefs, defineComponent } from "@vue/composition-api";
import store from "@/store/index.js";
import { repositoryFactory } from "@/repository/repositoryFactory";
import setting from "@/common/setting.js";
import ModelShopSite from "@/model/shopSite.js";

export default defineComponent({
  setup(props, ctx) {
    const shopSitesRepository = repositoryFactory.get("shopSites");
    const shopSiteContentsRepository =
      repositoryFactory.get("shopSiteContents");
    const sitesRepository = repositoryFactory.get("sites");
    const synchroRepository = repositoryFactory.get("synchro");
    const updateContentsRepository = repositoryFactory.get("updateContents");

    const state = reactive({
      showSite: [],
      dialog: false, // ダイアログ表示用
      urlHide: false,
      hpLink: false,
      item: {
        id: 0,
        site_id: 0,
        default_site_id: 0,
        loginid: "",
        password: "",
        auth_url: "",
        memo: "",
        color: "",
        mask: "!#XXXXXXXX",
        menu: false,
        proxy_server: null,
        category: "",
      },
      loginCheckLoading: false,
      loginCheckButtonText: "ログイン確認",
      siteContents: [],
      headers: [
        {
          text: "",
          value: "data-table-select",
          width: 30,
        },
        {
          text: "コンテンツ名",
          value: "site_content_name",
          sortable: false,
          class: "td_site_content_name",
        },
        {
          text: "タイプ",
          value: "content_name",
          sortable: false,
          class: "td_content_name",
        },
        { text: "タイトル", value: "title_limit", sortable: false },
        { text: "内容　　", value: "body_limit", sortable: false },
        { text: "最低間隔", value: "min_interval", sortable: false },
      ],
      selected: [],
    });

    // 編集
    const showDialog = async (editItems) => {
      await store.dispatch("loadingIcon/showIcon"); // ローディング表示

      // サイトの情報を取得する
      state.siteContents.splice(0);
      state.selected.splice(0);
      await sitesRepository
        .get_with_content(editItems.site_id)
        .then((response) => {
          if (response.data) {
            state.showSite = response.data;
            if (response.data.content) {
              Object.keys(response.data.content).forEach(function (key) {
                const name_option = response.data.content[key].is_update
                  ? ""
                  : "（取込のみ）";
                const item = {
                  "site_content_id": response.data.content[key].id,
                  "site_content_name":
                    response.data.content[key].name + name_option,
                  "content_icon": response.data.content[key].content_icon,
                  "content_name": response.data.content[key].content_name,
                  "title_limit":
                    response.data.content[key].title_limit == 0
                      ? "-"
                      : response.data.content[key].title_limit + "文字まで",
                  "body_limit":
                    response.data.content[key].body_limit == 0
                      ? "-"
                      : response.data.content[key].body_limit + "文字まで",
                  "min_interval":
                    response.data.content[key].min_interval == 0
                      ? "-"
                      : response.data.content[key].min_interval + "分",
                  "content_enable": true,
                };
                state.siteContents.push(item);
                if (editItems.id == 0) {
                  state.selected.push(item);
                }
              });
            }
          }
        })
        .catch((error) => {
          throw (
            "ERROR:shopSiteDialog.vue/init sitesRepository.list (" + error + ")"
          );
        });

      if (editItems.id != 0) {
        const params = {
          shop_site_id: editItems.id,
        };
        state.selected.splice(0);
        await shopSiteContentsRepository
          .list_shopsite(params)
          .then((response) => {
            if (response.data) {
              Object.keys(response.data).forEach(function (key) {
                if (response.data[key].is_enable) {
                  const item = state.siteContents.find(function (element) {
                    return (
                      element.site_content_id ==
                      response.data[key].site_content_id
                    );
                  });
                  state.selected.push(item);
                }
              });
            }
          })
          .catch((error) => {
            throw (
              "ERROR:shopSiteDialog.vue/init shopSiteContentsRepository.list (" +
              error +
              ")"
            );
          });
      }

      // ダイアログにアイテムを格納
      state.item.id = editItems.id;
      state.item.site_id = editItems.site_id;
      state.item.loginid = editItems.loginid;
      state.item.password = editItems.password;
      state.item.auth_url = editItems.auth_url;
      state.item.memo = editItems.memo;
      state.item.color = editItems.color;
      // 新規かつエステラブの時、PROXYサーバをあらかじめ設定しておく
      if (editItems.id == 0 && editItems.site_id == setting.eslove_site_id) {
        state.item.proxy_server = process.env.VUE_APP_ESLOVE_PROXY;
      } else {
        state.item.proxy_server = editItems.proxy_server;
      }
      state.item.category = editItems.category;

      // default_auth_urlが設定されている媒体は、URL入力欄を非表示にする
      if (state.showSite.default_auth_url) {
        state.urlHide = true;
      } else {
        state.urlHide = false;
      }
      // ホームページ連携の場合は、フラグを立てる
      if (state.item.category == setting.site_category_hp_link) {
        state.hpLink = true;
        state.urlHide = false;
      } else {
        state.hpLink = false;
      }

      // ダイアログ開く
      state.dialog = true;
      scrollReset();

      // ローディング非表示
      await store.dispatch("loadingIcon/hideIcon");
    };

    // 保存処理
    const onSubmit = async () => {
      // 入力チェック
      const isValid = await ctx.refs.observer.validate();

      if (!isValid) {
        // エラー処理
      } else {
        const logincheck = await checkLogin();
        if (logincheck) {
          if (!window.confirm("保存してよろしいですか？")) {
            return false;
          }
        } else {
          if (
            !window.confirm(
              "媒体にログインできませんでした。保存してよろしいですか？"
            )
          ) {
            return false;
          }
        }
        store.dispatch("loadingIcon/showIcon"); // ローディング表示
        // 新規か更新か
        if (state.item.id == 0) {
          // 新規 データ追加処理(店舗媒体)
          const params = {
            shop_id: store.getters["shops/currentShop"].id,
            site_id: state.item.site_id,
            auth_url: state.item.auth_url,
            loginid: state.item.loginid,
            password: state.item.password,
            config: JSON.stringify({
              "memo": state.item.memo,
              "color": state.item.color,
            }),
            proxy_server: state.item.proxy_server,
          };
          await shopSitesRepository
            .create(params)
            .then((response) => {
              if (response.data) {
                // データ追加OK
                state.item.id = response.data.id; // idをセット
                ctx.refs.observer.reset(); // 入力エラー表示クリア
              }
            })
            .catch((error) => {
              throw (
                "ERROR:ShopSiteDialog.vue/onSubmit shopSitesRepository.create (" +
                error +
                ")"
              );
            });

          // エステ魂の場合に限り、スケジュールの自動更新設定を実行しておく
          await saveEstamaSchedule();
        } else {
          // 更新
          const params = {
            id: state.item.id,
            shop_id: store.getters["shops/currentShop"].id,
            site_id: state.item.site_id,
            loginid: state.item.loginid,
            password: state.item.password,
            auth_url: state.item.auth_url,
            config: JSON.stringify({
              "memo": state.item.memo,
              "color": state.item.color,
            }),
            proxy_server: state.item.proxy_server,
          };
          // データ更新処理
          await shopSitesRepository
            .update(params)
            .then((response) => {
              if (response.data) {
                // 更新OK
                ctx.refs.observer.reset(); // 入力エラー表示クリア
              }
            })
            .catch((error) => {
              throw (
                "ERROR:ShopSiteDialog.vue/onSubmit shopSitesRepository.update (" +
                error +
                ")"
              );
            });
        }

        // shop_site_contentsテーブル更新
        await saveShopSiteContents();

        // ローディング非表示
        store.dispatch("loadingIcon/hideIcon");

        // vuex更新
        await ModelShopSite.setStore();

        cancelDialog();
        ctx.emit("save");
      }
    };

    const saveShopSiteContents = async () => {
      // shop_site_contentsテーブル更新
      const site_content_ids = [];
      for (let i = 0; i < state.selected.length; i++) {
        site_content_ids.push(state.selected[i].site_content_id);
      }
      const params = {
        shop_site_id: state.item.id,
        site_content_id: site_content_ids,
      };
      await shopSiteContentsRepository
        .save_list(params)
        .then((response) => {
          if (response.data) {
            // 更新OK
            if (!response.data.result) {
              alert("店舗媒体コンテンツの保存に失敗しました。");
              return false;
            }
          }
        })
        .catch((error) => {
          throw (
            "ERROR:ShopSiteDialog.vue/onSubmit shopSitesRepository.update (" +
            error +
            ")"
          );
        });
    };

    const saveEstamaSchedule = async () => {
      if (state.item.site_id == setting.esutama_site_id) {
        await updateContentsRepository
          .save_esutama_schedule({
            shop_id: store.getters["shops/currentShop"].id,
          })
          .then((response) => {
            if (response.data) {
              // データ追加OK
            }
          })
          .catch((error) => {
            throw (
              "ERROR:ShopSiteDialog.vue/onSubmit updateContentsRepository.save_esutama_schedule (" +
              error +
              ")"
            );
          });
      }
    };

    // ダイアログキャンセルボタン
    const cancelDialog = () => {
      ctx.refs.observer.reset(); // 入力エラー表示クリア
      state.dialog = false;
    };

    // ログインチェック
    const checkLogin = async () => {
      // ホームページ連携の場合は、ログインチェックしない
      if (state.hpLink) {
        return true;
      }
      // 入力チェック
      const isValid = await ctx.refs.observer.validate();
      if (!isValid) {
        // エラー処理
        return false;
      } else {
        // ログインチェック中は、ボタン・カードをdisabledに。
        state.loginCheckLoading = true;
        state.loginCheckButtonText = "確認中";
        // ログインチェック処理
        const params = {
          shop_id: store.getters["shops/currentShop"].id,
          site_id: state.item.site_id,
          auth_url: state.item.auth_url,
          loginid: state.item.loginid,
          password: state.item.password,
          shop_site_id: state.item.id,
          proxy_server: state.item.proxy_server,
        };
        const result = await synchroRepository
          .login_check(params)
          .then((response) => {
            if (response.data.status) {
              // 確認OK
              state.loginCheckButtonText =
                "<span style='color:blue'>確認できました</span>";
              setTimeout(() => {
                state.loginCheckButtonText = "ログイン確認";
              }, 5000);
              return true;
            } else {
              // 確認NG
              state.loginCheckButtonText =
                "<span style='color:red'>ログインできません</span>";
              setTimeout(() => {
                state.loginCheckButtonText = "ログイン確認";
              }, 5000);
              return false;
            }
          })
          .catch((error) => {
            throw (
              "ERROR:ShopSite.vue/checkLogin synchroRepository.login_check (" +
              error +
              ")"
            );
          });
        // 結果
        state.loginCheckLoading = false;
        return result;
      }
    };

    const swatchStyle = (item) => {
      return {
        backgroundColor: item.color,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: item.menu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out",
      };
    };

    const scrollReset = () => {
      setTimeout(() => {
        document.getElementsByClassName("scrollable-content")[0].scrollTop = 0;
        // document.querySelector(".scrollable-content").scroll(0, 0);
        // console.log("sReset");
      }, 200);
    };

    const rowClick = (item, row) => {
      row.select(!row.isSelected);
    };

    // 返却オブジェクト定義
    return {
      setting,
      ...toRefs(state),
      onSubmit,
      cancelDialog,
      showDialog,
      checkLogin,
      swatchStyle,
      scrollReset,
      rowClick,
    };
  },
});
</script>

<style scoped>
::v-deep #color-selector .v-input__append-inner {
  margin-top: 13px;
}
.edit_area {
  cursor: pointer;
}
::v-deep .td_content_name {
  width: 160px;
}
::v-deep .td_site_content_name {
  min-width: 160px;
}
</style>
