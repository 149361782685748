var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-subtitle-2 pa-1 blue--text blue lighten-5"},[_vm._v(" 契約内容により追加できる媒体が異なります。詳細は運営にお問い合わせください。 ")]),_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"（キーワード検索）","single-line":"","hide-details":"","clearable":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('shop-site-dialog-component',{ref:"shopSiteDialog",on:{"save":_vm.save}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.desserts,"search":_vm.search,"item-key":"id","mobile-breakpoint":0,"items-per-page":50,"footer-props":{
        'items-per-page-options': [50, 100, -1],
        'items-per-page-text': '表示件数',
      }},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(props.pageStart)+" ～"+_vm._s(props.pageStop)+"件／全"+_vm._s(props.itemsLength)+"件 ")]}},{key:"item.add",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{staticClass:"pa-1",on:{"click":function($event){return _vm.addItem(item)}}},[_vm._v(" mdi-plus-box ")])]}},{key:"item.category",fn:function(ref){
      var item = ref.item;
return [_c('span',{class:("font-weight-bold " + (item.category == _vm.setting.site_category_a_type
              ? 'orange--text'
              : item.category == _vm.setting.site_category_b_type
              ? 'blue--text'
              : item.category == _vm.setting.site_category_c_type
              ? 'green--text'
              : item.category == _vm.setting.site_category_hp_link
              ? 'purple--text'
              : 'black--text'))},[_vm._v(_vm._s(item.category))])]}},{key:"item.content",fn:function(ref){
          var item = ref.item;
return _vm._l((item.content),function(content,index){return _c('v-chip',{key:index,staticClass:"ma-1 font-weight-bold",attrs:{"color":content.is_update ? 'success' : 'warning',"small":""}},[_vm._v(" "+_vm._s(content.name)+_vm._s(content.is_update ? "" : "※取込のみ")+" ")])})}},{key:"no-data",fn:function(){return [_vm._v(" 表示するデータがありません ")]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }